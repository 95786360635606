import * as THREE from "three";
import { type ReactNode, useEffect, useMemo, useRef } from "react";
import { CHART_OBJECT_CANDLE_DEFAULTS } from "consts";
import { calcX as CalcX, calcY as CalcY } from "../helpers";
import type {
	CandleStyle,
	ChartCandleObject,
	ScaleFactor,
} from "types";

type CandlesProps = {
	candles: Array<ChartCandleObject>;
	style: CandleStyle;
	scaleFactor: ScaleFactor;
	minvol: number;
	maxvol: number;
};

const defaultProps = {
	style: CHART_OBJECT_CANDLE_DEFAULTS.style,
};

// This only runs ONCE per update - i.e. the useEffect here
// will respond to a change in the list of candles, and calculate
// and add to the main frame buffer - which once done, means we
// don't touch any calculations again until we need too.
const Candles = ({
	scaleFactor,
	candles,
	style,
	minvol,
	maxvol,
}: CandlesProps): ReactNode => {
	const bodyRef = useRef<THREE.InstancedMesh>(null);
	const wickRef = useRef<THREE.InstancedMesh>(null);
	
	// Sets up the meshes relative to one another - i.e. the wick
	// will be a percentage of the body x,y,z - which allows us
	// to tweak as necessary. The reason for memoizing them it to
	// prevent Three loosing reference to the objects as it updates
	// a frame during the render loop - this is just a memory optimisation.
	const bodyGeometry = useMemo(() => new THREE.BoxGeometry(1, 1, 1), []);
	const wickGeometry = useMemo(() => new THREE.BoxGeometry(style.wickWidthScale, 1, 0.4), [style]);
	const material = useMemo(() => new THREE.MeshStandardMaterial({ vertexColors: true }), []);
	
	// Make ref'ing this a little simpler.
	const candleCount = candles.length;
	
	useEffect(() => {
		const bodyMesh = bodyRef.current;
		const wickMesh = wickRef.current;
		
		if (!bodyMesh || !wickMesh) return;
		
		// A dump object that we use to build up the geometry, then copy
		// to the instance mesh once it's been built up.
		const dummy = new THREE.Object3D();
		
		// Make it less of a faff to calc x and y.
		const calcX = (x: number) => CalcX(x, scaleFactor);
		const calcY = (y: number) => CalcY(y, scaleFactor);
		
		// Can be varied if we feel like the candle depth isn't
		// showing the information in a useful manner.
		const depthScale = 1.5;  // Overall depth scaling
		const powerFactor = 0.5; // Power factor for volume scaling, set as square root
		
		// Function to scale volume using power scale, this approach should provide
		// control over the variance in trading volumes, i.e. values close to 0 will
		// make depths more uniform (but never completely uniform). Values around
		// 0.5 (the square root) often provide a good balance. Values closer to 1
		// will make the scaling more linear, and values above 1 will emphasize
		// differences in larger volumes - we just need to decide over time.
		const scaleVolume = (volume: number) => {
			return Math.pow((volume - minvol) / (maxvol - minvol), powerFactor);
		};
		
		// Create the two colours we need, and something to hold the vertex colours
		// in to push to the buffered material.
		const green = new THREE.Color(style.positiveCandleColor);
		const red = new THREE.Color(style.negativeCandleColor);
		const bodyColors = new Float32Array(candleCount * 3);
		const wickColors = new Float32Array(candleCount * 3);
		
		candles.forEach((candle, i) => {
			const { o, c, h, l, v, dt_from_ms, dt_to_ms } = candle.candle; // eslint-disable-line camelcase
			
			const from = dt_from_ms / 1000; // eslint-disable-line camelcase
			const to = dt_to_ms / 1000; // eslint-disable-line camelcase
			
			// Calculate the body of the candle
			const bodyTop = calcY(Math.max(o, c));
			const bodyBottom = calcY(Math.min(o, c));
			const bodyHeight = Math.max(Math.abs(bodyTop - bodyBottom), 0.001);
			const bodyY = (bodyTop + bodyBottom) / 2;
			
			const width = (calcX(to) - calcX(from)) * 0.35; // Allow some gaps between candles
			const depth = scaleVolume(v) * width * depthScale; // Relate the depth do the width of the candle
			
			// Set the body and the scaling - i.e. creating a simple "box"
			// at out coordinates, then scaling it in size x,y,z accordingly.
			dummy.position.set(calcX(from), bodyY, 0);
			dummy.scale.set(width, bodyHeight, depth);
			dummy.updateMatrix();
			
			bodyMesh.setMatrixAt(i, dummy.matrix);
			
			// Apply the body colour
			(c >= o ? green : red).toArray(bodyColors, i * 3);
			
			// Calculate the wick - following the same approach, but it
			// will be dependent on the wickGeometry set above.
			const wickTop = calcY(h);
			const wickBottom = calcY(l);
			const wickHeight = wickTop - wickBottom;
			const wickY = (wickTop + wickBottom) / 2;
			
			dummy.position.set(calcX(from), wickY, 0);
			dummy.scale.set(width, wickHeight, depth);
			dummy.updateMatrix();
			
			wickMesh.setMatrixAt(i, dummy.matrix);
			
			// Apply the wick colour
			(c >= o ? green : red).toArray(wickColors, i * 3);
		});
		
		// Apply color attributes to geometries
		bodyMesh.geometry.setAttribute("color", new THREE.InstancedBufferAttribute(bodyColors, 3));
		wickMesh.geometry.setAttribute("color", new THREE.InstancedBufferAttribute(wickColors, 3));
		
		// Mark meshes for update
		bodyMesh.instanceMatrix.needsUpdate = true;
		wickMesh.instanceMatrix.needsUpdate = true;
		
		// Ensure the renderer knows the object has been updated
		bodyMesh.geometry.attributes.color.needsUpdate = true;
		wickMesh.geometry.attributes.color.needsUpdate = true;
	}, [candles, maxvol, minvol, scaleFactor, style, candleCount]);
	
	return (
		<>
			<group>
				<instancedMesh
					ref={bodyRef}
					args={[bodyGeometry, material, candleCount]}
				/>
				<instancedMesh
					ref={wickRef}
					args={[wickGeometry, material, candleCount]}
				/>
			</group>
		</>
	);
};

Candles.defaultProps = defaultProps;

export default Candles;
