import { createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";

export interface ChartState {
	rotation: boolean;
}

const initialState: ChartState = {
	rotation: false,
};

export const chartSlice = createSlice({
	name: "chart",
	initialState,
	reducers: {
		toggleRotation: (state): void => {
			state.rotation = !state.rotation;
		},
		disableRotation: (state): void => {
			state.rotation = false;
		},
	},
});

export const { toggleRotation, disableRotation } = chartSlice.actions;

export const selectRotation = (state: RootState): boolean => state.chart.rotation;

export default chartSlice.reducer;
