import { CHART_OBJECT_BOX_DEFAULTS } from "consts";
import { calcX, calcY } from "../helpers";
import type { ReactNode } from "react";
import type { ChartBoxObject, ScaledObject } from "types";

type BoxProps = ChartBoxObject & ScaledObject;

const defaultProps = {
	scale: CHART_OBJECT_BOX_DEFAULTS.scale,
	rotation: CHART_OBJECT_BOX_DEFAULTS.rotation,
	style: CHART_OBJECT_BOX_DEFAULTS.style,
};

const Box = ({
	objId,
	height,
	width,
	depth,
	position,
	scale,
	rotation,
	scaleFactor,
	style,
	startPoint,
}: BoxProps): ReactNode => {
	if (style.color === undefined) {
		style.color = CHART_OBJECT_BOX_DEFAULTS.style.color;
	}
	
	if (startPoint) {
		position = startPoint;
	}
	
	return (
		<group key={objId}>
			<mesh
				scale={[scale.x, scale.y, scale.z]}
				position={[calcX(position.x, scaleFactor), calcY(position.y, scaleFactor), position.z || 0]}
				rotation={[rotation.x, rotation.y, rotation.z]}
			>
				<boxGeometry
					args={[
						scaleFactor.scaleX * width,
						scaleFactor.scaleY * height,
						depth,
					]}
				/>
				<meshStandardMaterial color={style?.color} />
			</mesh>
		</group>
	);
};

Box.defaultProps = defaultProps;

export default Box;
