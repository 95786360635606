import { type ReactNode, useMemo, useState } from "react";
import AceEditor from "react-ace";
import { Box } from "@mui/material";
import { RunEventType } from "types";
import "ace-builds/src-noconflict/mode-sh";
import "ace-builds/src-noconflict/theme-clouds"; // light, gray gutter, yellow highlight
import "ace-builds/src-noconflict/theme-dracula"; //dark

import { DarkModeSwitch } from "components";
import { useGetRunEventsQuery } from "services";

import "./Console.css";

type ConsoleProps = {
	instanceId?: string;
	runId?: string;
	showInGrid?: boolean;
	overrideColor?: boolean;
};

function Console({ instanceId, runId, showInGrid = false, overrideColor }: ConsoleProps): ReactNode {
	const toggleDarkMode = () => setIsDarkMode(!isDarkMode);
	const [isDarkMode, setIsDarkMode] = useState(true);
	
	// react-ace docs
	// https://github.com/securingsincity/react-ace/blob/master/docs/Ace.md
	const editorOptions = {
		fontSize: 20,
		readOnly: true,
		showGutter: false,
	};
	
	// NB percentage works for width but NOT height
	const editorStyle = {
		width: "100%",
		...(showInGrid ? { height: "300px" } : {}),
		zIndex: 0,
	};
	
	const { events } = useGetRunEventsQuery(
		{
			instanceId: instanceId ?? "",
			runId: runId ?? "",
			msgtype: [
				RunEventType.STDOUT,
				RunEventType.STDERR,
			],
		},
		{
			skip: !instanceId || !runId,
			selectFromResult: ({ data, isLoading }) => ({
				events: data?.events ?? [],
				isLoadingEvents: isLoading,
			}),
		}
	);
	
	const consoleColor = overrideColor !== undefined ? overrideColor : isDarkMode;
	
	const theme = consoleColor ? "dracula" : "clouds";
	
	const currentCode = useMemo(() => {
		let code = "";
		
		(events || []).forEach((event) => {
			if (event.eventType === RunEventType.STDOUT) {
				code += event.params.line;
			} else if (event.eventType === RunEventType.STDERR) {
				code += event.params.line; // TODO: colour console
			}
		});
		
		return code;
	}, [events]);
	
	return (
		<Box component="div" data-testid="ace-editor">
			<AceEditor
				editorProps={{ $blockScrolling: true }}
				mode="sh"
				setOptions={editorOptions}
				style={editorStyle}
				theme={theme}
				value={currentCode}
			/>
			{!showInGrid && <DarkModeSwitch toggle={toggleDarkMode} isDarkMode={isDarkMode} />}
		</Box>
	);
}

export default Console;
