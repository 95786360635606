import { Line as DreiLine } from "@react-three/drei";
import { CHART_OBJECT_LINE_DEFAULTS } from "consts";
import { calcX, calcY } from "../helpers";
import type { ReactNode } from "react";
import type { ChartLineObject, ChartPoint, ScaledObject } from "types";

interface LineProps extends Omit<ChartLineObject, "objtype" | "points">, ScaledObject {
	points?: Array<ChartPoint>,
}

const defaultProps = {
	style: CHART_OBJECT_LINE_DEFAULTS.style,
};

const Line = ({
	objId,
	points,
	style,
	scaleFactor,
}: LineProps): ReactNode => {
	if (!points || !scaleFactor) return;
	
	const threePoints: Array<[number, number, number]> = points.map((point) => {
		return [calcX(point.x, scaleFactor), calcY(point.y, scaleFactor), point.z || 0];
	});
	
	return (
		<>
			<DreiLine
				key={objId}
				points={threePoints}
				color={style.color}
				lineWidth={style.lineWidth || defaultProps.style.lineWidth}
				dashed={style.dashed}
			/>
		</>
	);
};

Line.defaultProps = defaultProps;

export default Line;
