import { type ReactNode, useEffect, useState } from "react";
import { OrthographicCamera } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useAppSelector } from "hooks";
import { selectRotation } from "store";
import { calcX, calcY } from "../helpers";
import type { ChartCameraProps } from "types";

export const ChartCamera = ({
	camRef,
	chartPos,
	orbitalRef,
}: ChartCameraProps): ReactNode => {
	const camRefCurrent = camRef?.current;
	const orbitalRefCurrent = orbitalRef?.current;
	
	const [xStops, setXStops] = useState<number[]>([]);
	const [yStops, setYStops] = useState<number[]>([]);
	
	// Gets the current rotation enable/disable - and gives
	// us something to allow a "reset" to trigger an update.
	const rotation = useAppSelector<boolean>(selectRotation);
	const [, forceUpdate] = useState<{}>();
	
	// This adds some limits to the pan position to limit
	// the camera into what is possible re x and y pos.
	// TODO - sort the locking here, it's "ok", but when
	// we reset loads, it creates calculation discrepancies.
	useEffect(() => {
		if (!camRefCurrent || !orbitalRefCurrent) return;
		function disablePan() {
			const [minX, maxX] = xStops;
			const [minY, maxY] = yStops;
			
			if (minX === undefined || maxX === undefined || minY === undefined || maxY === undefined) return;
			
			const newX = Math.min(Math.max(this.target.x, minX), maxX);
			const newY = Math.min(Math.max(this.target.y, minY), maxY);
			
			if (newX !== this.target.x || newY !== this.target.y) {
				// this.target.x = camRefCurrent.position.x = newX;
				// this.target.y = camRefCurrent.position.y = newY;
			}
		}
		if (orbitalRefCurrent && camRefCurrent) {
			orbitalRefCurrent.addEventListener("change", disablePan);
		}
		
		return () => {
			orbitalRefCurrent.removeEventListener("change", disablePan);
		};
	}, [camRefCurrent, orbitalRefCurrent, xStops, yStops]);
	
	// We have two sets of widths/heights here, we have the viewport as
	// it exists in user space (pixels in the view), and what
	// we have relative to the camera frustum - i.e. what happens
	// to the view when it's zoomed in and out. Size is the
	// former, and viewport is the latter.
	const sWidth = useThree((state) => state.size.width);
	const sHeight = useThree((state) => state.size.height);
	const vWidth = useThree((state) => state.viewport.width);
	const vHeight = useThree((state) => state.viewport.height);
	
	const zoom = useThree((state) => state.camera.zoom);
	const x = useThree((state) => state.camera.position.x);
	const y = useThree((state) => state.camera.position.y);
	
	// This keeps us safe from recalculations, subscribing only to what
	// we want to listen to above, it means that we don't get asked to
	// do expensive calculations on every frame render.
	useEffect(() => {
		const {
			mints,
			maxts,
			minX,
			maxX,
			minval,
			maxval,
			minY,
			maxY,
			x: prevX,
			y: prevY,
			zoom: prevZoom,
			scaleFactor,
		} = chartPos.current;
		
		// Calculate and update the window onto the data, here
		// we will calculate the deltas of what the user is doing
		// at any one time in regards their position on the
		// timeline, and up/down pan position.
		const deltaX = x - prevX;
		const deltaY = y - prevY;
		const centerX = (minX + maxX) / 2;
		const centerY = (minY + maxY) / 2;
		
		// Calculate new range based on zoom, initial load, the
		// range will be what we get, if we have zoomed, we
		// need to check this changed and re-calc.
		let rangeX = maxX - minX;
		let rangeY = maxY - minY;
		
		if (prevZoom !== 0) {
			rangeX = (maxX - minX) * (prevZoom / zoom);
			rangeY = (maxY - minY) * (prevZoom / zoom);
		}
		
		// Calculate new shift x/y values
		const shiftX = (deltaX / vWidth) * rangeX;
		const shiftY = (deltaY / vHeight) * rangeY;
		
		const halfRangeX = rangeX / 2;
		const halfRangeY = rangeY / 2;
		
		// Updating these will tell other components that the window
		// the user is looking at has moved so that they can update
		// accordingly.
		chartPos.current.minX = centerX - halfRangeX + shiftX;
		chartPos.current.maxX = centerX + halfRangeX + shiftX;
		chartPos.current.minY = centerY - halfRangeY + shiftY;
		chartPos.current.maxY = centerY + halfRangeY + shiftY;
		
		// Update general properties
		chartPos.current.width = sWidth;
		chartPos.current.height = sHeight;
		chartPos.current.zoom = zoom;
		chartPos.current.x = x;
		chartPos.current.y = y;
		
		// This should keep us from ever accidentally setting zero's
		if (!scaleFactor) return;
		
		// We are calculating the stops based on the entire data that we
		// have access to, not just the window. Of note is keep the y
		// indent that's carried over in the ScaleFactor calculations.
		setXStops([
			calcX(mints, scaleFactor) + (sWidth / zoom) / 2,
			calcX(maxts, scaleFactor) - (sWidth / zoom) / 2,
		]);
		setYStops([
			(calcY(minval, scaleFactor) + ((sHeight / zoom) * 0.75) / 2),
			(calcY(maxval, scaleFactor) - ((sHeight / zoom)  * 0.75) / 2),
		]);
	}, [sWidth, sHeight, vWidth, vHeight, x, y, zoom, chartPos]);
	
	// This allows us to reset the camera when rotation is turned off
	// by the user. At the moment, we do return the user back to the
	// centre of the first window - which is probably not that good.
	// The question here, with this kind of chart data, how often
	// are users going to use "rotation"?
	useEffect(() => {
		if (!camRefCurrent || !orbitalRefCurrent || rotation) return;
		
		camRefCurrent.rotation.set(0, 0, 0);
		camRefCurrent.position.set(0, 0, 10);
		camRefCurrent.zoom = 1;
		
		camRefCurrent.updateProjectionMatrix();
		
		orbitalRefCurrent.target.set(0, 0, 0);
		orbitalRefCurrent.update();
		
		forceUpdate({});
	}, [rotation, camRefCurrent, orbitalRefCurrent, forceUpdate]);
	
	return (
		<OrthographicCamera // Ref: https://threejs.org/docs/#api/en/cameras/OrthographicCamera
			makeDefault
			ref={camRef}
			// Define a viewing frustum: https://en.wikipedia.org/wiki/Viewing_frustum, To prevent
			// clipping during rotation, we set the frustum length to match the viewport width.
			near={Math.floor((sWidth / 2) * -1)}
			far={Math.ceil(sWidth / 2)}
		/>
	);
};

export default ChartCamera;
