import { ChartContainer, Loading } from "components";
import { ToastContext, ToastContextType } from "context";
import { useContext, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { fetchQueryData } from "utils";
import { useExecuteQueryQuery, useGetQueryQuery } from "services";
import { AggLevelType, ContainerProps } from "types";

const TrendQuery = ({
	setBreadcrumbs,
	setHeaderActions,
}: ContainerProps) => {
	const { queryId, isLoading } = useParams();
	const location = useLocation();
	const { createToast } = useContext(ToastContext) as ToastContextType;
	
	const queryParams = new URLSearchParams(location.search);
	const symbol = queryParams.get("symbol");
	const agg = queryParams.get("agg");
	const { data: currentQuery } = useGetQueryQuery(queryId ?? "", {
		skip: !queryId,
	});
	
	const sql =  currentQuery?.code ? currentQuery.code : "SELECT * FROM ticker_aggs LIMIT 15;";
	
	const {
		data: queryExecutionResult,
		isFetching: queryExecutionIsFetching,
		isError: queryExecutionIsError,
	} = useExecuteQueryQuery({ sql }, { skip: sql === "" });
	
	useEffect(() => {
		if (queryExecutionIsError) {
			createToast("Something went wrong when executing the SQL query");
		}
	}, [queryExecutionIsError, createToast]);
	
	useEffect(() => {
		setBreadcrumbs([{ text: `Historic Match ${queryId}/${symbol} - ${agg}` }]);
		setHeaderActions({
			create: [],
		});
	}, [
		setBreadcrumbs,
		setHeaderActions,
		queryId,
		symbol,
		agg,
	]);
	
	if (!queryExecutionResult || !symbol || !agg) return;
	
	const data = queryExecutionResult[symbol][agg];
	
	if (!data) return;
	
	return (
		<>
			{(isLoading || queryExecutionIsFetching) && <Loading message="Loading query..." />}
			<ChartContainer
				aggLevel={agg as AggLevelType}
				fetcher={
					async(mints: number, maxts: number) => fetchQueryData(mints, maxts, data)
				}
				mints={data.mints}
				maxts={data.maxts}
			/>
		</>
	);
};

export default TrendQuery;