import { useEffect, useState } from "react";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight, ViewInAr } from "@mui/icons-material";
import { getFormattedDate } from "utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectRotation, toggleRotation } from "store";
import { sigPointsTs } from "./SignificantPoints";
import type { MutableRefObject, ReactNode } from "react";
import type { ChartPos, MousePos } from "types";

interface TimestampMap {
	ts: number;
	timeF: string;
	timeF2: string;
	day: string;
	month: string;
	year: string;
}

const ALL_KEYS = ["timeF", "day", "month", "year"];

type AxisProps = {
	mousePos?: MousePos,
	maxSteps: number,
	label?: string,
	chartPos: MutableRefObject<ChartPos>,
};

const formatDateToTime = (r: Date) => {
	const formatted = getFormattedDate(r, "HH:mm:ss");
	
	if (formatted.slice(-3) === ":00") {
		return formatted.slice(0, -3);
	}
	
	return formatted;
};

const mapTimestamp = (r: number): TimestampMap => {
	const currentDate = new Date(r * 1000);
	
	return {
		ts: r,
		timeF: formatDateToTime(currentDate),
		timeF2: formatDateToTime(currentDate),
		day: getFormattedDate(currentDate, "ddd DD"),
		month: getFormattedDate(currentDate, "MMM"),
		year: getFormattedDate(currentDate, "YYYY"),
	};
};

export const XAxis = ({
	mousePos,
	maxSteps,
	label,
	chartPos,
}: AxisProps): ReactNode => {
	const dispatch = useAppDispatch();
	const rotation = useAppSelector<boolean>(selectRotation);
	
	const [show, setShow] = useState<boolean>(true);
	const [ticks, setTicks] = useState<TimestampMap[]>([]);
	const [labelKeys, setLabelKeys] = useState<string[]>([]);
	const [skippedLabelKeys, setSkippedLabelKeys] = useState<string[]>([]);
	
	const { mints, maxts, minX, maxX, width } = chartPos.current;
	const { layerX } = mousePos || { layerX: 0 };
	const xPos = minX + layerX / (width / (maxX - minX));
	
	useEffect(() => {
		setShow(false);
		
		const handler = setTimeout(() => {
		 	const ticks = sigPointsTs({
				minV: minX,
				maxV: maxX,
				maxDivisions: maxSteps,
			}).map((r) => mapTimestamp(r));
			
			const skipLabels: { [key:string]: boolean } = {};
			
			ALL_KEYS.forEach((key) => {
				if ([...new Set(ticks.map((t) => t[key as keyof TimestampMap]))].length <= 1) {
					skipLabels[key] = true;
				}
			});
			
			setLabelKeys(ALL_KEYS.filter((key) => !skipLabels[key]));
			setSkippedLabelKeys(ALL_KEYS.filter((key) => skipLabels[key]));
			setTicks(ticks);
			setShow(true);
		}, 500);
		
		return () => {
			clearTimeout(handler);
		};
	}, [minX, maxX, maxSteps, setShow, setTicks, setLabelKeys, setSkippedLabelKeys]);
	
	return (
		<Grid
			container
			item
			xs
			direction="column"
			sx={{ position: "relative", mt: 1 }}
		>
			<Grid container item direction="row" xs sx={{ minHeight: "32px" }}>
				{show && !rotation && ticks.map((t, i) => {
					return (
						<Grid
							item
							key={i}
							sx={{
								position: "absolute",
								translate: "-45% 0",
								whiteSpace: "nowrap",
								left:
									(width * (t.ts - minX)) /
									(maxX - minX),
							}}
						>
							{labelKeys.map((key) => t[key as keyof TimestampMap]).join(" ")}
						</Grid>
					);
				})}
			</Grid>
			{show && !rotation && (<Grid container item direction="row" xs>
				<Grid
					item
					sx={{
						position: "absolute",
						translate: "-45% 0",
						left:
							(width * (mints - minX)) /
							(maxX - minX),
					}}
				>
					<Tooltip title="Lower limit of data">
						<KeyboardArrowRight fontSize="small" />
					</Tooltip>
				</Grid>
				<Grid
					item
					sx={{
						position: "absolute",
						translate: "-45% 0",
						left:
							(width * (maxts - minX)) /
							(maxX - minX),
					}}
				>
					<Tooltip title="Upper limit of data">
						<KeyboardArrowLeft fontSize="small" />
					</Tooltip>
				</Grid>
			</Grid>)}
			<Grid container item justifyContent="center" xs={2}>
				<Grid item xs>
					<Tooltip title="Enabled/Disable rotation">
						<IconButton onClick={() => dispatch(toggleRotation())}
							sx={(theme) => ({
								color: !rotation
									? "text.secondary"
									: theme.palette.mode === "light"
										? "info.main"
										: "text.primary",
							})}
						>
							<ViewInAr />
						</IconButton>
					</Tooltip>
				</Grid>
				<Grid item xs={4} sx={{ textAlign: "center", paddingTop: 1, whiteSpace: "nowrap"  }}>
					{show && !rotation && (label
						? label.toLocaleUpperCase()
						: skippedLabelKeys
							.map((key) => (ticks[0] ? ticks[0][key as keyof TimestampMap] : ""))
							.join(" "))}
				</Grid>
				<Grid item xs />
			</Grid>
			{show && !rotation && (<Box
				component="div"
				alignItems="center"
				sx={{
					position: "absolute",
					backgroundColor: "green",
					translate: "-45% 0",
					zIndex: 15,
					whiteSpace: "nowrap",
					left: layerX,
				}}
			>
				{labelKeys.map((key) => mapTimestamp(xPos)[key as keyof TimestampMap]).join(" ")}
			</Box>)}
		</Grid>
	);
};
