// TODO: Rename utils files and move "functions" into relevant categories
import { AggLevelType, FetchedData, QueryDataIndex, QueryResult } from "types";
import type {
	AnyTypedFunction,
	AssetData,
	AssetDataItem,
	ChartCandleObject,
	ChartData,
	QueryResults,
} from "types";

export const debounce = (
	expensiveFunction: AnyTypedFunction,
	delay: number
): AnyTypedFunction => {
	let timeoutId: NodeJS.Timeout;
	
	return function(...args): void {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(
			() => expensiveFunction.apply(this, args),
			delay
		);
	};
};

export const streamAsyncIteratorByLine = async function* (
	stream: ReadableStream
): AsyncGenerator<string, any, any> {
	// Get a lock on the stream
	const reader = stream.getReader();
	const utf8Decoder = new TextDecoder("utf-8");
	
	const re = /\r\n|\n|\r/gm;
	let buffer = "";
	
	try {
		let done = false;
		let chunk;
		
		while (!done) {
			// Populate the buffer
			// NOTE: reader.read() does not work on safari
			({ done, value: chunk } = await reader.read());
			if (chunk) buffer += utf8Decoder.decode(chunk, { stream: true });
			// Consume the buffer
			const lines = buffer.split(re);
			
			buffer = lines.pop() ?? "";
			for (const line of lines) yield line;
		}
		// Deal with any remainder
		if (buffer) yield buffer;
	} finally {
		reader.releaseLock();
	}
};

export const flattenAssetData = (
	assetData: AssetData
): Array<AssetDataItem> => {
	const items: Array<AssetDataItem> = [];
	
	(assetData.data || []).forEach((assetDataNode) => {
		if (assetDataNode.data) {
			assetDataNode.data.forEach((item) => items.push(item));
		}
	});
	
	return items;
};

// This will take candle data when it comes in row-by-row, and determine
// the various min/max values from the candle, and convert that to
// something that the chart can then use to visualise the data.
export const convertQueryRowData  = (res: QueryResults, data: ChartData) => {
	const candle = (data?.params.chartcmd.obj as ChartCandleObject).candle;
	const sym = candle.symbol;
	const agg = candle.agg_level as AggLevelType;
	
	if (!sym ||  !agg) return;
	
	res[sym] = res[sym] || {};
	
	res[sym][agg] = res[sym][agg] || {
		data: [],
		mints: 0,
		maxts: 0,
		index: {} as QueryDataIndex,
	};
	
	if (res[sym][agg].mints === 0) {
		res[sym][agg].mints = candle.dt_from_ms / 1000;
	}
	
	res[sym][agg].mints = Math.min(res[sym][agg].mints, candle.dt_from_ms / 1000);
	res[sym][agg].maxts = Math.max(res[sym][agg].maxts, candle.dt_to_ms / 1000);
	
	// Add the timestamp here, this is always 0 from the API
	data.timestamp = candle.dt_from_ms / 1000;
	
	// Sort the data as is comes in, this will slow things down, but will
	// make it much faster for the user to interact with.
	res[sym][agg].data.push(data);
	res[sym][agg].data.sort((a, b) => a.timestamp - b.timestamp);
	
	// Yes, each time we get a new row, we have to create a new index lookup.
	// This should speed things elsewhere, fingers crossed.
	res[sym][agg].index = res[sym][agg].data.reduce((acc, item: ChartData, index) => {
		acc[item.timestamp] = index;
		
		return acc;
	}, {} as QueryDataIndex);
};

// This is a universal fetcher that can be used by components that need to
// "fetch" data with the chart, and not overload it too much. It relies on
// the index, and will calculate the basic window data for the chart to use,
// i.e. the minY/maxY etc.
export const fetchQueryData = async(mints: number, maxts: number, result: QueryResult): Promise<FetchedData> => {
	const { data, index } = result;
	let fetchedData: FetchedData = {
		data: [],
		minY: 0,
		maxY: 0,
		minLow: 0,
		maxVol: 0,
	};
	
	if (data.length === 0) return fetchedData;
	
	const { start, end } = getIndexes(index, mints, maxts);
	const selection: ChartData[] = data.slice(start, end + 1);
	
	fetchedData = selection.reduce((acc, item: ChartData) => {
		const candle = (item.params.chartcmd.obj as ChartCandleObject).candle;
		
		if (acc.minY === 0) {
			acc.minY = candle.l;
		}
		
		acc.minY = acc.minLow = Math.min(acc.minY, candle.l);
		acc.maxY = Math.max(acc.maxY, candle.h);
		acc.maxVol = Math.max(acc.maxVol, candle.v);
		
		acc.data.push(item);
		
		return acc;
	}, fetchedData);
	
	return new Promise(
		(resolve) => setTimeout(() => resolve(fetchedData), 500)
	);
};

// Gets the start and end indexes from the QueryDataIndex that we create
// as the data is coming in. This should be "fast" if we're lucky enough
// to get exact timestamps that are in our data set, otherwise, we have
// to search - which is then carried out the the findNearest function.
const getIndexes = (index: QueryDataIndex, mints: number, maxts: number) => {
	const start = index[mints] !== undefined
		? index[mints]
		: findNearest(index, mints, true);
	
	const end = index[maxts] !== undefined
		? index[maxts]
		: findNearest(index, maxts, false);
	
	return { start, end };
};

// Search the index for matches - this could possibly have been done on
// the data array, but perhaps this will be faster, and more throw away,
// it's very hard to tell. It does mean above that we can sometimes get
// direct matches, which will be really fast.
const findNearest = (index: QueryDataIndex, timestamp: number, start: boolean): number => {
	const timestamps = Object.keys(index).map(Number);
	
	if (start) {
		const next = timestamps.find(t => t >= timestamp);
		
		return next ? index[next] : timestamps.length - 1;
	} else {
		const prev = timestamps.reverse().find(t => t <= timestamp);
		
		return prev ? index[prev] : 0;
	}
};
