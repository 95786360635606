import { CHART_OBJECT_BOX_DEFAULTS } from "consts";
import { calcX, calcY } from "../helpers";
import type { ReactNode } from "react";
import type { ChartBoxObject, ScaledObject } from "types";

interface BoxesProps extends Pick<ChartBoxObject, "scale" | "rotation" | "style">, ScaledObject {
	boxes: ChartBoxObject[],
}

const defaultProps = {
	scale: CHART_OBJECT_BOX_DEFAULTS.scale,
	rotation: CHART_OBJECT_BOX_DEFAULTS.rotation,
	style: CHART_OBJECT_BOX_DEFAULTS.style,
};

const Boxes = ({
	boxes,
	scale,
	rotation,
	style,
	scaleFactor,
}: BoxesProps): ReactNode => {
	return (
		<>
			{boxes.map((box) => (
				<group key={box.objId}>
					<mesh
						scale={[box?.scale?.x ?? scale.x, box?.scale?.y ?? scale.y, box?.scale?.z ?? scale.z]}
						position={[
							calcX(box.startPoint.x, scaleFactor),
							calcY(box.startPoint.y, scaleFactor),
							box.startPoint.z || 0,
						]}
						rotation={[
							box?.rotation?.x ?? rotation.x,
							box?.rotation?.y ?? rotation.y,
							box?.rotation?.z ?? rotation.z,
						]}
					>
						<boxGeometry
							args={[
								scaleFactor.scaleX * box.width,
								scaleFactor.scaleY * box.height,
								box.depth,
							]}
						/>
						<meshStandardMaterial color={box.style?.color ?? style.color} />
					</mesh>
				</group>
			))}
		</>
	);
};

Boxes.defaultProps = defaultProps;

export default Boxes;
