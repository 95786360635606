import { useCallback, useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import WrenchIcon from "@mui/icons-material/PlumbingRounded";
import {
	CHART_AXIS_X_STEPWIDTH,
	CHART_AXIS_Y_STEPHEIGHT,
	DEFAULT_X_DIVISIONS,
	DEFAULT_Y_DIVISIONS,
} from "consts";
import { BigCard, Chart } from "components";
import { AggLevelType } from "types";
import { useAppDispatch } from "hooks";
import { disableRotation } from "store";
import { XAxis } from "components/common/chart/helpers/XAxis";
import { YAxis } from "components/common/chart/helpers/YAxis";
import { EmptyChartPos, calculateScaleFactor } from "../helpers";
import type { ReactNode } from "react";
import type {
	ChartAxis,
	ChartData,
	ChartPos,
	Fetcher,
	MousePos,
} from "types";

type ChartContainerProps = {
	axis: {
		bottom: ChartAxis;
		right: ChartAxis;
	};
	aggLevel?: AggLevelType,
	fetcher?: Fetcher,
	liveEvents?: Array<ChartData>; // TODO - when the harvester is working, implement live data
	maxts?: number,
	mints?: number,
	timespanOverride?: number,
};

const defaultProps = {
	axis: {
		bottom: { enabled: true, timestamps: true },
		right: { enabled: true, timestamps: false },
	},
};

const ChartContainer = ({
	axis,
	liveEvents,
	aggLevel,
	mints,
	maxts,
	fetcher,
	timespanOverride,
}: ChartContainerProps): ReactNode => {
	const dispatch = useAppDispatch();
	
	const [mousePos, setMousePos] = useState<MousePos>(); // This helps us track mouse pos in the axis
	const [events, setEvents] = useState<ChartData[]>([]);
	
	// Defines the initial setup of the main ChartPos ref.
	const chartPos = useRef<ChartPos>({ ...EmptyChartPos, fetcher });
	
	// This allows us to append to a list of events over time, and
	// just leave them there, in the hope that they will render fine.
	const appendEvents = useCallback((data: ChartData[]) => {
		setEvents((prev) => [...prev, ...data]);
	}, []);
	
	// Calculate divisions for axis and gridlines - default to 4
	const maxXSteps = chartPos
		? Math.floor(chartPos.current.width / CHART_AXIS_X_STEPWIDTH)
		: DEFAULT_X_DIVISIONS;
	const maxYSteps = chartPos
		? Math.floor(chartPos.current.height / CHART_AXIS_Y_STEPHEIGHT) / 2
		: DEFAULT_Y_DIVISIONS;
	
	// When the agg level, min/max ts change, we need to set everything up,
	// this is only focused on the x axis, as that is the basis of what
	// we use to get data, and that is important.
	useEffect(() => {
		if (!mints || !maxts || !aggLevel) return;
		
		// This defines what we are showing in the chart viewport initially.
		let timespan = 5 * 24 * 60 * 60; // Default 5 days - show a week
		
		switch (aggLevel) {
			case AggLevelType.SEC:  // Show one hour
				timespan = 60 * 60;
				break;
			case AggLevelType.MIN:  // Show two hours
				timespan = 2 * 60 * 60;
				break;
			case AggLevelType.HOUR: // Show approx one trading session (day)
				timespan = 7 * 60 * 60;
		}
		
		// Allow for an implementing component to override the default
		// timespan set from the aggregate.
		if (timespanOverride) {
			timespan = timespanOverride;
		}
		
		const min = Math.max(mints, maxts - timespan);
		
		// Viewport timestamps for use with a specific width, we keep
		// what the chart "can" show (mints, maxts), and what it will
		// initially show with no zoom (minX, maxX). We are unable to
		// set anything to do with the y values, as these are only
		// returned when the min/max timestamps are used.
		chartPos.current.minX = min;
		chartPos.current.maxX = maxts;
		chartPos.current.mints = mints;
		chartPos.current.maxts = maxts;
		
		// This should set how many "pages" of data we need to load,
		// first reset what we need to load if the agg level changes.
		chartPos.current.windows = {};
		chartPos.current.timespan = timespan;
		chartPos.current.scaleFactor = undefined;
		
		// Then get the number of pages, and round down the min (which should
		// be the LHS of the chart) to the nearest hour. This will then allow the
		// chart to compare with this value to check if a new page needs to be
		// loaded.
		let pages = Math.ceil((maxts - mints) / timespan);
		let start = min;
		let acc = min - (min % timespan);
		
		while (pages--) {
			chartPos.current.windows[acc] = start;
			start -= timespan;
			acc -= timespan;
		}
		
		// Ensure that the events are empty to start with.
		setEvents([]);
		
		// Ensure that rotation is always reset to off.
		dispatch(disableRotation());
	}, [aggLevel, mints, maxts, timespanOverride, dispatch]);
	
	// This will try and load pages with whatever fetcher has been given to
	// us, and will rely on any endpoint responding to the setting of min
	// and max timestamps.
	useEffect(() => {
		const { windows, timespan, minX } = chartPos.current;
		const windowCount = Object.values(windows).length;
		
		if (windowCount === 0 || !minX || !fetcher) return;
		
		// Round down to the nearest hour the minX, i.e. the LHS of the chart
		// which we are using for out "page" indicator.
		const rMin: number = minX - (minX % timespan);
		
		// If we have loaded this "page", then we do nothing as the data will
		// already be loaded and rendered. The assumption here is that we
		// load two additional pages to the left so that the users experience
		// of using the chart is as smooth as possible. It can also happen
		// Very quickly, meaning that sometimes we get a match, then the
		// fetcher has already gone off and got the data. If we only have
		// one "page", then we have to force that.
		if (windows[rMin - (windowCount === 1 ? 0 : timespan)] === undefined) return;
		
		const fetch: Array<Array<number>> = [];
		let tofetch: number = 0;
		
		// This decides how many windows of data to fetch - we can increase this
		// value if unsatisfactory.
		while (tofetch < 2) {
			const acc: number = (tofetch++) * timespan;
			const min: number = windows[rMin - acc];
			
			// Just to be sure!
			if (!min) continue;
			
			// This will create a "queue" of fetches that we need to make, and remove
			// the record that we've visited. The addition of 1 here is so that we
			// shouldn't load the same event twice from the API.
			fetch.push([min + 1, min + timespan]);
			delete chartPos.current.windows[rMin - acc];
		}
		
		// We now need to get the data - and as we are going to do this quickly,
		// we need to cope with the vagaries of RTK Query - and handle the
		// whole thing all at once in a list of promises.
		const getData = async() => {
			if (fetch.length === 0) return;
			
			try {
				const results = await Promise.all(
					fetch.map((f: number[]) => fetcher(...f as [number, number]))
				).catch(() => null);
				
				// Reset fetch to avoid endless loops
				fetch.length = 0;
				
				if (!results)	return;
				
				// Handle the unwrapped results
				results.forEach((result) => {
					const { data, minY, maxY, minLow, maxVol } = result;
					
					// Calculate the scale factor if it's empty. We also need to get the
					// minY/maxY values, as these will come in with each block.
					if (!chartPos.current.scaleFactor) {
						// We setup the initial min/max values that we get back. The
						// min/max vals will change as we load new data, min/max y
						// will change as the user moves the window.
						chartPos.current.minval = Number(minY);
						chartPos.current.minY = Number(minY);
						chartPos.current.maxval = Number(maxY);
						chartPos.current.maxY = Number(maxY);
						
						chartPos.current.low = Number(minLow);
						chartPos.current.maxvol = Number(maxVol);
						
						// Calculate the initial ScaleFactor
						chartPos.current.scaleFactor = calculateScaleFactor(
							chartPos, Number(minY), Number(maxY)
						);
					} else {
						chartPos.current.minval = Math.min(chartPos.current.minval, Number(minY));
						chartPos.current.maxval = Math.max(chartPos.current.maxval, Number(maxY));
					}
					
					appendEvents(data);
				});
			} catch (error) {
				console.error("Error fetching data:", error);
			}
			
		};
		
		getData();
	}, [appendEvents, fetcher, chartPos.current.minX]);
	
	useEffect(() => {
		if (!liveEvents || liveEvents.length === 0) return;
		appendEvents(liveEvents);
	}, [liveEvents, appendEvents]);
	
	return (
		<Grid
			container
			item
			direction="column"
			sx={{ userSelect: "none", height: "100%" }}
		>
			<Grid container item direction="row" xs>
				<Grid
					item
					xs={11}
					sx={(theme) => ({
						position: "relative",
						border: `1px solid ${theme.palette.chartBorder.main}`,
					})}
				>
					{events.length === 0 && (
						<BigCard
							icon={WrenchIcon}
							message="Visualisation of results goes here"
							absolute
						/>
					)}
					<Chart
						cmds={events}
						mousePos={mousePos}
						setMousePos={setMousePos}
						chartPos={chartPos}
						maxXSteps={maxXSteps}
						maxYSteps={maxYSteps}
					/>
				</Grid>
				<Grid container item xs={1}>
					{axis.right.enabled && events.length > 0 && (
						<YAxis
							settings={axis.right}
							maxSteps={maxYSteps}
							mousePos={mousePos}
							chartPos={chartPos}
							label={axis.right.label || undefined}
						/>
					)}
				</Grid>
			</Grid>
			<Grid container item xs={1}>
				{axis.bottom.enabled && events.length > 0 && (
					<XAxis
						maxSteps={maxXSteps}
						mousePos={mousePos}
						chartPos={chartPos}
						label={axis.bottom.label || undefined}
					/>
				)}
				<Grid item xs={1} />
			</Grid>
		</Grid>
	);
};

ChartContainer.defaultProps = defaultProps;

export default ChartContainer;
