import * as THREE from "three";
import { CHART_OBJECT_POLYGON_DEFAULTS } from "consts";
import { calcX, calcY } from "../helpers";
import type { ReactNode } from "react";
import type { ChartPolygonObject, ScaledObject } from "types";

type PolygonProps = ChartPolygonObject & ScaledObject;

const defaultProps = {
	scale: CHART_OBJECT_POLYGON_DEFAULTS.scale,
	rotation: CHART_OBJECT_POLYGON_DEFAULTS.rotation,
	style: CHART_OBJECT_POLYGON_DEFAULTS.style,
};

const Polygon = ({
	objId,
	scale,
	rotation,
	points,
	style,
	scaleFactor,
}: PolygonProps): ReactNode => {
	const poly = new THREE.Shape();
	const firstPoint = points[0];
	
	poly.moveTo(calcX(firstPoint.x, scaleFactor), calcY(firstPoint.y, scaleFactor));
	points.forEach((point) => {
		poly.lineTo(calcX(point.x, scaleFactor), calcY(point.y, scaleFactor));
	});
	poly.lineTo(calcX(firstPoint.x, scaleFactor), calcY(firstPoint.y, scaleFactor));
	poly.autoClose = true;
	
	const geometry = new THREE.ExtrudeGeometry(
		poly,
		CHART_OBJECT_POLYGON_DEFAULTS.extrudeSettings
	);
	
	return (
		<>
			<group
				key={objId}
				scale={[scale.x, scale.y, scale.z]}
				rotation={[rotation.x, rotation.y, rotation.z]}
			>
				<mesh geometry={geometry}>
					<meshPhongMaterial color={style.color} />
				</mesh>
			</group>
		</>
	);
};

Polygon.defaultProps = defaultProps;

export default Polygon;
