import { Button, Grid, Stack, Theme } from "@mui/material";
import type { ElementType, ReactNode } from "react";

type BigCardProps = {
	ctaLinkText?: string;
	handleClick?: () => void | Promise<void>;
	icon?: ElementType;
	iconPosition: "above" | "below";
	message?: string;
	absolute?: boolean;
};

const defaultProps = {
	iconPosition: "above",
	message: "These are not the droids you are looking for.",
};

const BigCard = ({
	ctaLinkText,
	handleClick,
	icon: Icon,
	iconPosition,
	message,
	absolute = false,
}: BigCardProps): ReactNode => {
	return (
		<Stack
			justifyContent="center"
			alignItems="center"
			sx={(theme: Theme) => ({
				height: "100%",
				width: "100%",
				position: absolute ? "absolute" : "relative",
				background: theme.palette.background.backdrop,
				zIndex: 16,
			})}
		>
			{Icon && iconPosition === "above" && (
				<Icon
					sx={(theme: Theme) => ({
						fontSize: theme.typography.bigIcon.fontSize,
					})}
				/>
			)}
			<Grid container justifyContent="center" alignItems="center">
				{message}
				{ctaLinkText && handleClick && (
					<Grid item>
						<Button variant="text" onClick={handleClick}>
							{ctaLinkText}
						</Button>
					</Grid>
				)}
			</Grid>
			{Icon && iconPosition === "below" && (
				<Icon
					sx={(theme: Theme) => ({
						fontSize: theme.typography.bigIcon.fontSize,
					})}
				/>
			)}
		</Stack>
	);
};

BigCard.defaultProps = defaultProps;

export default BigCard;
