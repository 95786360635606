import { convertQueryRowData, streamAsyncIteratorByLine } from "utils";
import { api } from ".";
import type {
	ApiQuery,
	ExecuteSQLRequest,
	Query,
	QueryData,
	QueryId,
	QueryResponseRow,
	QueryResults,
} from "types";

const convertToQuery = (query: ApiQuery): Query => ({
	id: query.executionId,
	groupId: query.executionId,
	name: query.queryName,
	code: query.queryCode || "SELECT * FROM ticker_aggs LIMIT 15;",
	commitSHA: query.commitSHA,
	startedAt: query.startedAt,
	completedAt: query.completedAt,
	createdAt: query.createdAt,
});

export const query = api
	.enhanceEndpoints({
		addTagTypes: ["queries", "query"],
	})
	.injectEndpoints({
		overrideExisting: false,
		endpoints: (build) => ({
			getQueries: build.query<Query[], void>({
				query() {
					return {
						url: "/queries",
						method: "GET",
					};
				},
				transformResponse: (response: QueryData) => {
					return response.queries.map(query => convertToQuery(query));
				},
				providesTags: ["queries"],
			}),
			getQuery: build.query<Query, QueryId>({
				query(queryId: QueryId) {
					return {
						url: `/queries/${queryId}`,
						method: "GET",
					};
				},
				transformResponse: (response: ApiQuery) => {
					return convertToQuery(response);
				},
				providesTags: ["query"],
			}),
			deleteQuery: build.mutation<void, QueryId>({
				query(queryId: QueryId) {
					return {
						url: `/queries/${queryId}`,
						method: "DELETE",
					};
				},
				invalidatesTags: ["queries", "query"],
			}),
			createQuery: build.mutation<Query, Partial<Query>>({
				query(body: Partial<Query>) {
					return {
						url: "/queries",
						method: "POST",
						body: {
							queryName: body.name,
							queryCode: body.code,
							groupId: "",
						},
					};
				},
				transformResponse: ({ content: query }: Partial<{content: ApiQuery}>) => {
					return convertToQuery(query || {} as ApiQuery);
				},
				invalidatesTags: ["queries", "query"],
			}),
			updateQuery: build.mutation<Query, Partial<Query>>({
				query(body: Partial<Query>) {
					return {
						url: `/queries/${body.id}`,
						method: "PUT",
						body: {
							queryName: body.name,
							queryCode: body.code,
						},
					};
				},
				transformResponse: (query: ApiQuery) => {
					return convertToQuery(query);
				},
				invalidatesTags: ["queries", "query"],
			}),
			executeQuery: build.query<QueryResults, ExecuteSQLRequest>({
				/*
					The result of this query is streamed and returned line by line.
					
					This is a bit of a hacky implementation using an onChunk() method passed with the query args to send
					chunks back as streaming isn't officially supported by RTK.
					See https://github.com/reduxjs/redux-toolkit/issues/3701
					
					onChunk() is called with a list of candles returned by the endpoint so far, at the end a final
					result with all candles is also returned.
				*/
				query(queryArguments: ExecuteSQLRequest) {
					return {
						url: `/queries/execute`,
						method: "POST",
						body: {
							query: queryArguments.sql,
						},
						responseHandler: async(response) => {
							if (!response.body) throw new Error("Error while running SQL query");
							
							let results: QueryResults = {};
							
							for await (const line of streamAsyncIteratorByLine(response.body)) {
								// Inefficient, it returns each line individually regardless how many are in a chunk
								const row = JSON.parse(line) as QueryResponseRow;
								
								if (row.status === 200) {
									convertQueryRowData(results, row.content);
								}
							}
							
							return results;
						},
					};
				},
				serializeQueryArgs: ({ queryArgs }) => {
					// This custom serializer is needed to remove the onChunk method that cant be serialized
					return queryArgs.sql;
				},
			}),
		}),
	});

export const {
	useGetQueriesQuery,
	useDeleteQueryMutation,
	useCreateQueryMutation,
	useUpdateQueryMutation,
	useExecuteQueryQuery,
	useGetQueryQuery,
} = query;