import { type ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Collapse, Grid, IconButton, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import {
	ChartContainer,
	GridContainer,
	InstanceRunsTable,
	Loading,
	MetaDataTable,
	Table,
} from "components";
import {
	useGetInstanceQuery,
	useGetRunEventsQuery,
	useGetRunsQuery,
	useLazyGetRunEventsQuery,
} from "services";
import { formatDateTime, formatDateTimeWithSeconds } from "utils";
import { AggLevelType, MetricsType, RunEventType } from "types";

import type {
	ChartData,
	ContainerProps,
	FetchedData,
	Fetcher,
	InstanceId,
	Run,
	RunEvent,
	RunId,
} from "types";

interface RunHistory extends Run {
	id: Run["runId"];
}

const InstanceResultsContainer = ({
	setBreadcrumbs,
	setHeaderActions,
}: ContainerProps): ReactNode => {
	// STATE
	const [expanded, setExpanded] = useState<boolean>(false);
	
	// CONTEXT & OTHER HOOKS
	const navigate = useNavigate();
	const { instanceId, runId } = useParams();
	
	// RTK QUERY
	const { data: instance, isLoading } = useGetInstanceQuery(
		instanceId as InstanceId
	);
	const { runHistory, run, isLoadingRunHistory } = useGetRunsQuery(
		instanceId as InstanceId,
		{
			selectFromResult: ({ data, isLoading }) => {
				const runHistory =
					data?.instanceRuns.map((r) => ({ ...r, id: r.runId })) ?? [];
				
				return {
					run: runHistory.find((r) => r.runId === runId),
					isLoadingRunHistory: isLoading,
					runHistory,
				};
			},
		}
	);
	
	const { runEvents, aggLevel, mints, maxts, isLoadingRunEvents } = useGetRunEventsQuery(
		{
			instanceId: instanceId as InstanceId,
			runId: runId as RunId,
			metrics: [
				MetricsType.AGG_LEVEL,
				MetricsType.MIN_MAX_TIMESTAMPS,
			],
			msgtype: [
				RunEventType.STARTED,
				RunEventType.CANCELLED,
				RunEventType.REJECTED,
				RunEventType.HEARTBEAT,
				RunEventType.COMPLETED,
			],
		},
		{
			skip: !instanceId || !runId,
			selectFromResult: ({ data, isLoading }) => ({
				isLoadingRunEvents: isLoading,
				runEvents: data?.events ?? [],
				aggLevel: data?.aggLevel ?? AggLevelType.HOUR,
				mints: data?.tsFrom ?? 0,
				maxts: data?.tsTo ?? 0,
			}),
		}
	);
	
	// This will setup a fetcher with so that the ChartContainer
	// can then fetch data according to it's min/max data points.
	const [fetch] = useLazyGetRunEventsQuery();
	const fetchRunEvents: Fetcher = async(mints: number, maxts: number): Promise<FetchedData> => {
		const { events, minY, maxY, minLow, maxVol } = await fetch({
			msgtype: [RunEventType.CHART],
			metrics: [MetricsType.MIN_LOW_VOLUME],
			instanceId: instanceId as InstanceId,
			runId: runId as RunId,
			tsFrom: mints,
			tsTo: maxts,
		}).unwrap();
		
		return {
			data: (events ?? []) as ChartData[],
			minY: minY ?? 0,
			maxY: maxY ?? 0,
			minLow: minLow ?? 0,
			maxVol: maxVol ?? 0,
		};
	};
	
	const isReady = !isLoading && !isLoadingRunHistory && !isLoadingRunEvents;
	
	// REACT HOOKS
	useEffect(() => {
		setBreadcrumbs([
			{ text: "Instances", url: "/instances" },
			{ text: "Run history" },
		]);
		setHeaderActions();
	}, [setBreadcrumbs, setHeaderActions]);
	
	// TODO: remove the results table, as this can get huge, and it's
	// only meant to be temporary.
	return (
		<Grid container direction="column" spacing={6} pb={5}>
			{!isReady && <Loading message="Loading results..." />}
			{isReady && (
				<>
					<Grid item>
						<Typography variant="h1">Results for {instance?.name}</Typography>
					</Grid>
					<GridContainer>
						<ChartContainer
							fetcher={fetchRunEvents}
							aggLevel={aggLevel}
							mints={mints}
							maxts={maxts}
						/>
					</GridContainer>
					<Grid item>
						<Typography variant="h1">
							Run event data for {instance?.name}{" "}
							<IconButton
								onClick={() => setExpanded(!expanded)}
								sx={{
									transition: "transform 0.5s",
									transform: !expanded ? "rotate(-90deg)" : "rotate(0deg)",
								}}
							>
								<ExpandMore />
							</IconButton>
						</Typography>
					</Grid>
					<Grid item>
						<Collapse in={expanded}>
							<Table<RunEvent>
								getRowId={(row: RunEvent) => row.eventId}
								columns={[
									{
										id: "Event type",
										label: "Event type",
										mapper: (row: RunEvent) => row.eventType,
									},
									{
										id: "Output",
										label: "Output",
										mapper: (row: RunEvent) => {
											if ("line" in row.params && row.params.line !== undefined)
												return row.params.line;
											
											return "";
										},
									},
									{
										id: "Date",
										label: "Date",
										mapper: (row: RunEvent) =>
											formatDateTimeWithSeconds(row.timestamp),
									},
								]}
								rows={runEvents}
							/>
						</Collapse>
					</Grid>
				</>
			)}
			{isReady && (
				<>
					<Grid container item>
						<MetaDataTable<RunHistory>
							metadata={run}
							dataToShow={[
								{
									field: "startedAt",
									headerName: "Started at",
									valueFormatter: (value) => formatDateTime(value as number),
								},
								{
									field: "stoppedAt",
									headerName: "Stopped at",
									valueFormatter: (value) => formatDateTime(value as number),
								},
							]}
						/>
					</Grid>
					{runId && (
						<InstanceRunsTable
							runId={runId}
							runHistory={runHistory}
							handleShowRun={(runId) =>
								navigate(`/instances/${instanceId}/runs/${runId}`)
							}
						/>
					)}
				</>
			)}
		</Grid>
	);
};

export default InstanceResultsContainer;
