import { type ReactNode, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Stack, Typography } from "@mui/material";
import {
	Info as InfoIcon,
	Newspaper as NewspaperIcon,
	Timeline as TimelineIcon,
	Timeline as TrendingUpIcon,
} from "@mui/icons-material";

import {
	AssetMetadataTable,
	BigCard,
	ChartContainer,
	GridContainer,
	Loading,
	RequestAssetDataForm,
	RequestAssetDataFormInitialState,
	RequestHarvesterForm,
	TabButtons,
} from "components";
import {
	useDeleteHarvesterRequestMutation,
	useDeleteHistoricalDataRequestMutation,
	useGetAssetQuery,
	useLazyGetCandleDataForAssetQuery,
} from "services";
import { useAppDispatch } from "hooks";
import { setRecentlyViewedAsset } from "store";
import { getJSDate } from "utils";
import type {
	AggLevelType,
	AssetDataResolution,
	AssetTickerSymbol,
	ContainerProps,
	FetchedData,
	Fetcher,
	Interval,
	StockTimeFrame,
} from "types";

const timeFrameDefaults: StockTimeFrame[] = [
	{
		name: "1H",
		resolution: "second",
		order: 1,
		mints: 0,
		maxts: 0,
		timespan: 60 * 60,
	},
	{
		name: "1D",
		resolution: "minute",
		order: 2,
		mints: 0,
		maxts: 0,
		timespan: 60 * 60 * 7,
	},
	{
		name: "1W",
		resolution: "minute",
		order: 3,
		mints: 0,
		maxts: 0,
		timespan: 60 * 60 * 24 * 7,
	},
	{
		name: "1M",
		resolution: "hour",
		order: 4,
		mints: 0,
		maxts: 0,
		timespan: 60 * 60 * 24 * 31,
	},
	{
		name: "3M",
		resolution: "day",
		order: 5,
		mints: 0,
		maxts: 0,
		timespan: 60 * 60 * 24 * 31 * 3,
	},
	{
		name: "1Y",
		resolution: "day",
		order: 6,
		mints: 0,
		maxts: 0,
		timespan: 60 * 60 * 24 * 365,
	},
];

const defaultBreadcrumbs = [
	{
		text: "Stock Data",
		url: "/stock-data",
	},
];

const StockDataContainer = ({
	setBreadcrumbs,
	setHeaderActions,
}: ContainerProps): ReactNode => {
	const dispatch = useAppDispatch();
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [assetFormData, setAssetFormData] = useState<RequestAssetDataFormInitialState | null>(null);
	const [displayHarvesterRequestForm, setDisplayHarvesterRequestForm] = useState(false);
	const [displayDataRequestForm, setDisplayDataRequestForm] = useState<boolean>(false);
	const [currentTab, setCurrentTab] = useState("Graph");
	const [timeFrame, setTimeFrame] = useState<StockTimeFrame[]>([]);
	const [selected, setSelected] = useState<StockTimeFrame>();
	const [mints, setMints] = useState<number>(0);
	const [maxts, setMaxts] = useState<number>(0);
	const [resolution, setResolution] = useState<string>("second");
	const [timespan, setTimespan] = useState<number>();
	
	// RTK Query
	const { assetId } = useParams();
	const {
		data: selectedAsset,
		isLoading,
		isError,
	} = useGetAssetQuery(assetId as AssetTickerSymbol);
	
	const [deleteHistoricalDataRequest] =	useDeleteHistoricalDataRequestMutation();
	const [deleteHarvesterRequest] = useDeleteHarvesterRequestMutation();
	
	// Create a fetcher for the ChartContainer
	const [fetch] =	useLazyGetCandleDataForAssetQuery();
	const fetchAssetData: Fetcher = async(mints: number, maxts: number): Promise<FetchedData> => {
		return await fetch({
			symbol: selectedAsset?.ticker ?? "",
			resolution: resolution as AssetDataResolution,
			tsFrom: mints,
			tsTo: maxts,
		}).unwrap();
	};
	
	/**
	 * Toggle forms methods
	 */
	const toggleDrawer = (): void => setIsDrawerOpen(!isDrawerOpen);
	const toggleDataRequestForm = useCallback((
		initialData: RequestAssetDataFormInitialState | null = null
	): void => {
		setAssetFormData(initialData);
		setDisplayDataRequestForm(!displayDataRequestForm);
	}, [displayDataRequestForm]);
	
	const toggleHarvesterRequestForm = useCallback((): void => {
		setDisplayHarvesterRequestForm(!displayHarvesterRequestForm);
	}, [displayHarvesterRequestForm]);
	
	// CONTEXT & OTHER HOOKS
	const pageTitle =
		!isLoading && selectedAsset ? selectedAsset.name : "Asset Data";
	
	useEffect(() => {
		if (selectedAsset) {
			setBreadcrumbs([
				...defaultBreadcrumbs,
				{
					text: selectedAsset.ticker,
					type: "pill",
				},
			]);
			
			// Only trigger recently viewed if it was possible to
			// download the ticker data.
			dispatch(setRecentlyViewedAsset(selectedAsset));
		} else {
			setBreadcrumbs(defaultBreadcrumbs);
		}
	}, [
		setBreadcrumbs,
		dispatch,
		selectedAsset,
	]);
	
	useEffect(() => {
		setHeaderActions({
			create: [
				{
					label: "Populate Historical",
					onClick: toggleDataRequestForm,
					createIcon: true,
				},
				{
					label: "Populate Realtime",
					onClick: toggleHarvesterRequestForm,
					createIcon: true,
				},
			],
		});
	}, [
		setHeaderActions,
		toggleDataRequestForm,
		toggleHarvesterRequestForm,
	]);
	
	// Once the asset is loaded, we need to see what blocks we have so
	// that the user can select data that actually exists, rather than
	// having a bunch of buttons that show "nothing".
	useEffect(() => {
		if (!selectedAsset) return;
		
		const enabled: { [key: string]: StockTimeFrame } = {};
		
		selectedAsset?.intervals.forEach((i) => {
			if (i.loaded) {
				timeFrameDefaults
					.filter((t) => t.resolution === i.resolution)
					.forEach((t => {
						enabled[t.name] = enabled[t.name] || t;
						
						if (enabled[t.name].mints === 0) {
							enabled[t.name].mints = i.tsFrom;
						}
						
						enabled[t.name].mints = Math.min(enabled[t.name].mints, i.tsFrom);
						enabled[t.name].maxts = Math.max(enabled[t.name].maxts, i.tsTo);
					}));
			}
		});
		
		setTimeFrame(Object.values(enabled).sort((a, b) => a.order > b.order ? 1 : -1));
	}, [selectedAsset, setTimeFrame]);
	
	// This should only ever happen once when the above works out what
	// to do with the timeframe
	useEffect(() => {
		if (timeFrame.length === 0) return;
		setSelected(timeFrame[0]);
	}, [timeFrame, setSelected]);
	
	// Set the various properties from the selected timeframe
	useEffect(() => {
		if (!selected) return;
		
		setMints(selected.mints);
		setMaxts(selected.maxts);
		setResolution(selected.resolution);
		setTimespan(selected.timespan);
	}, [selected, setMints, setMaxts, setResolution, setTimespan]);
	
	return (
		<Stack>
			<Grid container justifyContent="space-between" mt={4}>
				<Grid item md={10} lg={11}>
					<Typography variant="h1">{pageTitle}</Typography>
				</Grid>
			</Grid>
			{isLoading && <Loading message="Loading stock data..." />}
			{!isLoading && isError && (
				<BigCard
					ctaLinkText="Choose one now"
					handleClick={toggleDrawer}
					icon={TrendingUpIcon}
					message="Failed to load asset data."
				/>
			)}
			{!isLoading && selectedAsset && timeFrame.length > 0 && (
				<TabButtons
					currentTab={currentTab}
					handleTabChange={(newTab) => setCurrentTab(newTab)}
					tabs={[
						{
							label: "Graph",
							icon: TimelineIcon,
						},
						{
							label: "News",
							icon: NewspaperIcon,
						},
						{
							label: "Info",
							icon: InfoIcon,
						},
					]}
				/>
			)}
			{!isLoading && selectedAsset && timeFrame.length > 0 && (
				<Stack>
					{currentTab === "Graph" && (
						<>
							<Grid container pt={3} pb={0.5} spacing={3}>
								{timeFrame.map((frame) => {
									return (
										<Grid
											className="asset-data__range-item"
											key={frame.name}
											item
											sx={{
												...(selected === frame
													? {
														textDecorationLine:
																"underline",
														textUnderlineOffset:
																"0.9rem",
														textDecorationThickness:
																"0.2rem",
													}
													: { opacity: 0.3 }),
											}}
											onClick={() =>
												setSelected(frame)
											}
										>
											{frame.name}
										</Grid>
									);
								})}
							</Grid>
							<GridContainer>
								<ChartContainer
									fetcher={fetchAssetData}
									aggLevel={resolution as AggLevelType}
									timespanOverride={timespan}
									mints={mints}
									maxts={maxts}
								/>
							</GridContainer>
						</>
					)}
					{currentTab === "News" && (
						<Stack my={4}>
							<Typography variant="h2">Latest news</Typography>
							<small>When the API is ready</small>
							<Box component="div">
								{/* News tile component here */}
							</Box>
						</Stack>
					)}
					{currentTab === "Info" && (
						<Stack my={4}>
							<p>Company description here</p>
							<p>{`Company number: ${"COMPANY #"}`}</p>
						</Stack>
					)}
				</Stack>
			)}
			{selectedAsset && (
				<AssetMetadataTable
					intervals={selectedAsset.intervals}
					populateBlock={(row: Interval) => {
						toggleDataRequestForm({
							dateFrom: getJSDate(row.tsFrom),
							dateTo: getJSDate(row.tsTo),
							resolution: row.resolution,
						});
					}}
					cancelRequest={(requestId: string) =>
						deleteHistoricalDataRequest(requestId)
					}
					stopHarvesting={(resolution: string) =>
						deleteHarvesterRequest({
							symbol: selectedAsset?.ticker ?? "",
							resolution: resolution as AssetDataResolution,
						})
					}
				/>
			)}
			<RequestAssetDataForm
				handleFormClose={() => setDisplayDataRequestForm(false)}
				selectedAsset={selectedAsset}
				initialState={assetFormData}
				show={displayDataRequestForm}
			/>
			<RequestHarvesterForm
				handleFormClose={() => setDisplayHarvesterRequestForm(false)}
				selectedAsset={selectedAsset}
				show={displayHarvesterRequestForm}
			/>
		</Stack>
	);
};

export default StockDataContainer;
