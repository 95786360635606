import { useCallback, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { METRICS } from "consts";
import { ChartContainer, GridContainer, SelectField } from "components";
import { getUnixTimestamp } from "utils";
import { RunEventType } from "types";

import type { ReactNode } from "react";
import type { ChartData, Metrics } from "types";

const DEFAULT_METRIC = "grossProfit";

type StyledMetrics = {
	lineId: string;
	metricsEvents?: Array<Metrics> | null;
	lineColor?: string;
};

type MetricsChartContainerProps = {
	metrics?: Array<StyledMetrics> | null;
};

const MetricsChartContainer = ({
	metrics,
}: MetricsChartContainerProps): ReactNode => {
	const [metricName, setMetricName] = useState<string>(DEFAULT_METRIC);
	
	const metricsSelector = useCallback(
		(m: Metrics) => m[metricName as keyof Metrics],
		[metricName]
	);
	
	// map metrics events to chart events
	const chartEvents = useMemo(() => {
		const events: ChartData[] = [];
		
		(metrics || []).forEach((styledMetric) => {
			(styledMetric.metricsEvents || []).forEach((metric) => {
				events.push({
					eventId: metric.orderCount,
					eventType: RunEventType.CHART,
					params: {
						chartcmd: {
							cmdtype: "DRAW_OBJ",
							obj: {
								points: [],
								objId: `${styledMetric.lineId}-${metricName}`,
								objtype: "LINE",
								append_points: [ // eslint-disable-line camelcase
									{
										x: getUnixTimestamp(metric.timestamp),
										y: metricsSelector(metric),
										z: 0,
									},
								],
								style: {
									color: styledMetric.lineColor || "red",
								},
							},
						},
					},
					runId: "metrics",
					timestamp: getUnixTimestamp(metric.timestamp),
				} as ChartData);
			});
		});
		
		return events;
	}, [metricsSelector, metrics, metricName]);
	
	return (
		<Grid container item direction="column" spacing={1}>
			<Grid item>
				<SelectField
					id="metrics"
					modal
					options={METRICS.map((metric) => ({
						label: metric.label,
						value: metric.name,
					}))}
					name="metrics"
					value={metricName}
					label="Metric Name"
					onChange={(e) => setMetricName(e.target.value as string)}
				/>
			</Grid>
			<GridContainer>
				<ChartContainer liveEvents={chartEvents} />
			</GridContainer>
		</Grid>
	);
};

export default MetricsChartContainer;
