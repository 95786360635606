import type { MutableRefObject } from "react";
import type { ChartPos, ScaleFactor } from "types";

const EmptyScaleFactor: ScaleFactor = {
	scaleX: 0,
	translateX: 0,
	scaleY: 0,
	translateY: 0,
};

export const EmptyChartPos: ChartPos = {
	height: 0,
	width: 0,
	x: 0,
	y: 0,
	zoom: 0,
	mints: 0,
	maxts: 0,
	minval: 0,
	maxval: 0,
	minX: 0,
	maxX: 0,
	minY: 0,
	maxY: 0,
	low: 0,
	maxvol: 0,
	windows: {},
	timespan: 0,
	fetcher: () => {},
};

export const calcX = (x: number, scaleFactor: ScaleFactor) => scaleFactor.translateX + scaleFactor.scaleX * x;
export const calcY = (y: number, scaleFactor: ScaleFactor) => scaleFactor.translateY + scaleFactor.scaleY * y;

export const invertX = (x: number, scaleFactor: ScaleFactor) => (x - scaleFactor.translateY) / scaleFactor.scaleY;
export const invertY = (y: number, scaleFactor: ScaleFactor) => (y - scaleFactor.translateY) / scaleFactor.scaleY;

export const calculateScaleFactor = (
	chartPos: MutableRefObject<ChartPos>,
	minY: number,
	maxY: number
): ScaleFactor => {
	if (!chartPos || !minY || !maxY) return EmptyScaleFactor;
	
	const { x, width, height, zoom, minX, maxX } = chartPos.current;
	
	if (!width || !height) return EmptyScaleFactor;
	
	// TODO - This seems hugely uncertain at the moment, it needs some
	// work, I don't think that the x value here is right, does
	// the x value need to incorporate the zoom value?
	const scaleX = width / (maxX - minX) / zoom;
	const translateX = -((maxX + minX) / 2) * scaleX + x;
	
	const scaleY = ((height / zoom) * 0.75) / (maxY - minY);
	const translateY = (-(minY + maxY) * scaleY) / 2;
	
	return {
		scaleX,
		translateX,
		scaleY,
		translateY,
	};
};
