import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useAppSelector } from "hooks";
import { selectRotation } from "store";
import { sigPointsNum } from "./SignificantPoints";

import type { MutableRefObject, ReactNode } from "react";
import type { ChartAxis, ChartPos, MousePos } from "types";

type AxisProps = {
	settings?: ChartAxis,
	mousePos?: MousePos,
	maxSteps: number,
	label?: string,
	chartPos: MutableRefObject<ChartPos>,
};

export const YAxis = ({
	mousePos,
	maxSteps,
	label,
	chartPos,
}: AxisProps): ReactNode => {
	const rotation = useAppSelector<boolean>(selectRotation);
	
	const [show, setShow] = useState<boolean>(true);
	const [results, setResults] = useState<string[]>([]);
	
	const { minY, maxY, height } = chartPos.current;
	const { layerY } = mousePos || { layerY: 0 };
	const yPos = maxY - layerY / (height / (maxY - minY));
	
	useEffect(() => {
		setShow(false);
		
		const handler = setTimeout(() => {
			setResults(sigPointsNum({
				minV: minY,
				maxV: maxY,
				maxDivisions: maxSteps,
			}));
			
			setShow(true);
		}, 500);
		
		return () => {
			clearTimeout(handler);
		};
	}, [minY, maxY, maxSteps, setShow, setResults]);
	
	return (
		<Grid
			container
			item
			direction="row"
			sx={{ position: "relative", ml: 1 }}
		>
			<Grid item xs>
				{show && !rotation && results.map((result, stepIndex) => {
					return (
						<Grid
							item
							key={stepIndex}
							sx={{
								position: "absolute",
								translate: "0 45%",
								bottom:
									(height *
										(parseFloat(result) - minY)) /
									(maxY - minY),
							}}
						>
							{result}
						</Grid>
					);
				})}
			</Grid>
			{show && layerY && !rotation ? (
				<Box
					component="div"
					sx={{
						position: "absolute",
						backgroundColor: "green",
						translate: "0 45%",
						bottom: height - layerY,
					}}
				>
					{yPos.toFixed(3)}
				</Box>
			) : null}
			{label && show && !rotation && (
				<Grid
					container
					item
					justifyContent="center"
					alignItems="center"
					xs={2}
				>
					<Box
						component="div"
						sx={{
							transformOrigin: "50% 50%",
							transform: "rotate(-90deg)",
						}}
					>
						{label.toLocaleUpperCase()}
					</Box>
				</Grid>
			)}
		</Grid>
	);
};
