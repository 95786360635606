import { Fragment, type MutableRefObject, useEffect, useRef, useState } from "react";
import {
	Annotations,
	Boxes,
	Candles,
	Line,
	Polygons,
	Volumes,
} from "components";
import { invertY } from "../helpers";
import type { ReactNode } from "react";
import type {
	ChartAnnotationObject,
	ChartBoxObject,
	ChartCandleObject,
	ChartData,
	ChartLineObject,
	ChartPoint,
	ChartPolygonObject,
	ChartPos,
} from "types";

type ChartObjectsProps = {
	chartPos: MutableRefObject<ChartPos>,
	cmds: ChartData[],
};

type ElementRef = {
	points?: ChartPoint[];
	candles?: ChartCandleObject[];
	boxes?: ChartBoxObject[];
	annotations?: ChartAnnotationObject[];
	polygons?: ChartPolygonObject[];
	element: React.ReactNode;
};

type ElementRefs = {
	[key: string]: ElementRef;
};

const ChartObjects = ({ chartPos, cmds }: ChartObjectsProps): ReactNode => {
	const elementRefs = useRef<ElementRefs>({});
	const [, forceUpdate] = useState<{}>();
	
	// This is the main renderer of all the chart objects, storing them in
	// a ref so that no recalculations have to be done - i.e. once the ref
	// is fully set up and calculated, the return of the ReactNode just
	// returns the fully formed elements.
	useEffect(() => {
		elementRefs.current = {};
		
		const { height, low, maxvol, scaleFactor } = chartPos.current;
		
		if (!scaleFactor) return;
		
		cmds.forEach((cmd) => {
			const obj = cmd.params.chartcmd.obj;
			
			switch (obj.objtype) {
				case "CANDLE":
					const candle = obj as ChartCandleObject;
					
					let candles: ChartCandleObject[] = [];
					
					if (elementRefs.current["candles"]) {
						const ref = elementRefs.current["candles"];
						
						candles = [...ref?.candles ?? []];
					}
					
					candles.push(candle);
					
					// Both candles and volumes take candles to render their
					// commensurate structures - so we have to add them both
					// in the one element.
					elementRefs.current["candles"] = {
						element: (
							<Fragment key="candles">
								<Candles scaleFactor={scaleFactor}
									candles={candles}
									minvol={low}
									maxvol={maxvol}
								/>
								<Volumes scaleFactor={scaleFactor}
									candles={candles}
									minvol={low}
									maxvol={maxvol}
									yMin={invertY(-(height / 2), scaleFactor)}
									yMax={invertY(height / 2, scaleFactor)}
								/>
							</Fragment>
						),
						candles,
					};
					break;
				case "BOX":
					let boxes: ChartBoxObject[] = [];
					
					if (elementRefs.current["boxes"]) {
						const ref = elementRefs.current["boxes"];
						
						boxes = [...ref?.boxes ?? []];
					}
					
					boxes.push(obj as ChartBoxObject);
					
					elementRefs.current[obj.objId] = {
						element: (<Boxes scaleFactor={scaleFactor} boxes={boxes} key="boxes" />),
						boxes,
					};
					break;
				case "ANNOTATION":
					let annotations: ChartAnnotationObject[] = [];
					
					if (elementRefs.current["annotations"]) {
						const ref = elementRefs.current["annotations"];
						
						annotations = [...ref?.annotations ?? []];
					}
					
					annotations.push(obj as ChartAnnotationObject);
					
					elementRefs.current["annotations"] = {
						element: (
							<Annotations scaleFactor={scaleFactor} annotations={annotations} key="annotations" />
						),
						annotations,
					};
					break;
				case "LINE":
					const line = obj as ChartLineObject;
					let points: ChartPoint[] = [];
					
					if (!elementRefs.current[obj.objId]) {
						points = line.points ?? line.append_points;
					} else {
						const ref = elementRefs.current[obj.objId];
						
						// As the points come in in any order, we unfortunately need to sort
						// them otherwise Three tries to connect the ends together.
						points = [...(ref?.points ?? []), ...(line?.append_points ?? [])].sort((a, b) => a.x - b.x);
					}
					
					elementRefs.current[obj.objId] = {
						element: (
							<Line scaleFactor={scaleFactor} {...line} points={points} key={obj.objId} />
						),
						points,
					};
					break;
				case "POLYGON":
					let polygons: ChartPolygonObject[] = [];
					
					if (elementRefs.current["polygons"]) {
						const ref = elementRefs.current["polygons"];
						
						polygons = [...ref?.polygons ?? []];
					}
					
					polygons.push(obj as ChartPolygonObject);
					
					elementRefs.current["polygons"] = {
						element: (<Polygons scaleFactor={scaleFactor} polygons={polygons} key="polygons" />),
						polygons,
					};
					break;
			};
		});
		
		forceUpdate({});
	}, [chartPos, cmds]);
	
	return (
		<>
			{Object.values(elementRefs.current).map((ref) => ref.element)}
		</>
	);
};

export default ChartObjects;
