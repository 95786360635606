import * as THREE from "three";
import { type ReactNode, useEffect, useMemo, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { Line } from "@react-three/drei";
import { Line2 } from "three-stdlib";
import { useAppSelector } from "hooks";
import { selectRotation } from "store";
import { calcX, calcY } from "../helpers";
import { sigPointsNum, sigPointsTs } from "./SignificantPoints";
import type { GridLinesProps } from "types";

const crossHairsStyle = {
	color: "#6357a8",
	lineWidth: 0.5,
	dashed: false,
};

const lineMaterial = new THREE.LineBasicMaterial({
	color: "#333D61",
	linewidth: 0.5,
	transparent: true,
	opacity: 0.3,
});

export const CrossHairs = (): ReactNode => {
	const horizontalRef = useRef<Line2 | null>(null);
	const verticalRef = useRef<Line2 | null>(null);
	const planeRef = useRef<THREE.Plane | null>(null);
	
	// Create an invisible plane that will be used for raycasting
	useEffect(() => {
		const plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
		
		planeRef.current = plane;
	}, []);
	
	useFrame((state) => {
		const { camera, raycaster, mouse, viewport } = state;
		
		if (!horizontalRef.current || !verticalRef.current || !planeRef.current) return;
		
		// Update raycaster with current mouse position.
		raycaster.setFromCamera(mouse, camera);
		
		// Find the intersection point of the ray with the plane.
		const intersectionPoint = new THREE.Vector3();
		
		raycaster.ray.intersectPlane(planeRef.current, intersectionPoint);
		
		// Update the position of the crosshairs.
		horizontalRef.current.position.set(
			camera.position.x,
			intersectionPoint.y,
			0
		);
		verticalRef.current.position.set(
			intersectionPoint.x,
			camera.position.y,
			0
		);
		
		// For orthographic camera, we can use the viewport size directly
		// Update the scale of the crosshairs.
		horizontalRef.current.scale.set(viewport.width, 1, 1);
		verticalRef.current.scale.set(1, viewport.height, 1);
	});
	
	return (
		<>
			<Line
				ref={horizontalRef}
				points={[
					[-1, 0, 0],
					[1, 0, 0],
				]}
				renderOrder={1}
				color={crossHairsStyle.color}
				lineWidth={crossHairsStyle.lineWidth}
				dashed={crossHairsStyle.dashed}
			/>
			<Line
				ref={verticalRef}
				points={[
					[0, -1, 0],
					[0, 1, 0],
				]}
				renderOrder={1}
				color={crossHairsStyle.color}
				lineWidth={crossHairsStyle.lineWidth}
				dashed={crossHairsStyle.dashed}
			/>
		</>
	);
};

export const GridLines = ({
	maxXSteps,
	maxYSteps,
	chartPos,
}: GridLinesProps): ReactNode => {
	const rotation = useAppSelector<boolean>(selectRotation);
	const groupXRef = useRef<THREE.Group<THREE.Object3DEventMap> | null>(null);
	const groupYRef = useRef<THREE.Group<THREE.Object3DEventMap> | null>(null);
	
	const size = 100; // An arbitrary value to scale
	
	const { minval, maxval, mints, maxts, timespan, scaleFactor } =
		chartPos.current;
	
	// This calcs the vertical axis x points, and does it for the whole
	// visible span that the user can scroll to - that's why we're working
	// out a rough idea for a replicable pattern from sigPointsTs.
	const xPoints = useMemo(() => {
		if (!scaleFactor) return [];
		
		// TODO - how do we make this better - on large amounts of data,
		// we can end up making waaaaay too many vertical grid lines,
		// so we need to reduce this in some way?
		if (maxts - mints > 31536000) return [];
		
		return sigPointsTs({
			minV: mints - (2 * timespan),
			maxV: maxts + (2 * timespan),
			maxDivisions: Math.round(((maxts - mints) / timespan) * maxXSteps),
		}).map((v) => {
			const x = calcX(v, scaleFactor);
			
			return new THREE.Line(
				new THREE.BufferGeometry().setFromPoints([
					new THREE.Vector3(x, size, 0),
					new THREE.Vector3(x, -size, 0),
				]),
				lineMaterial
			);
			
		});
	}, [timespan, mints, maxts, maxXSteps, scaleFactor]);
	
	// This calcs the vertical axis y points in a similar approach to the
	// x axis, i.e. for all the available y scale.
	const yPoints = useMemo(() => {
		if (!scaleFactor) return [];
		
		return sigPointsNum({
			minV: minval,
			maxV: maxval,
			maxDivisions: maxYSteps,
		}).map((v) => {
			const y = calcY(Number(v), scaleFactor);
			
			return new THREE.Line(
				new THREE.BufferGeometry().setFromPoints([
					new THREE.Vector3(-size, y, 0),
					new THREE.Vector3(size, y, 0),
				]),
				lineMaterial
			);
		});
	}, [minval, maxval, scaleFactor, maxYSteps]);
	
	useFrame((state) => {
		const { camera, viewport } = state;
		
		if (!groupXRef.current || !groupYRef.current) return;
		
		groupXRef.current.position.y = camera.position.y;
		groupYRef.current.position.x = camera.position.x;
		
		groupXRef.current.scale.setY(viewport.height / (size * 2));
		groupYRef.current.scale.setX(viewport.width / (size * 2));
	});
	
	if (rotation) return <></>;
	
	return (
		<>
			<group ref={groupXRef}>
				{xPoints.map((point, index) => (
					<primitive key={index} object={point} />
				))}
			</group>
			<group ref={groupYRef}>
				{yPoints.map((point, index) => (
					<primitive key={index} object={point} />
				))}
			</group>
		</>
	);
};
