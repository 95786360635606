import {
	Dispatch,
	type ReactNode,
	SetStateAction,
	useEffect,
	useState,
} from "react";
import {
	ListAlt as ListAltIcon,
	Timeline as TimelineIcon,
} from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { getUnixTimestamp } from "utils";
import { CHARTS, TABLE } from "consts";
import {
	BrokerOrderTable,
	ChartContainer,
	GridContainer,
	SelectField,
	TabButtons,
} from "components";
import { useLazyGetCandleDataForAssetQuery } from "services";
import type {
	AggLevelType,
	AssetDataResolution,
	Backtest,
	BrokerOrders,
	ChartData,
	FetchedData,
	Fetcher,
	Run,
} from "types";

interface SelectProp {
	assets: string[];
	selectedAsset: string | null;
	setSelectedAsset: Dispatch<SetStateAction<string | null>>;
}

interface Props {
	assets: string[];
	brokerOrders?: BrokerOrders;
	assetResolution: AssetDataResolution;
	setAssetResolution: Dispatch<SetStateAction<AssetDataResolution>>;
	isLoading: boolean;
	comparedRuns: Run[];
	backtest?: Backtest;
	orderAnnotations?: ChartData[];
}

const SelectedAssetField = ({
	assets,
	selectedAsset,
	setSelectedAsset,
}: SelectProp) => {
	useEffect(() => {
		if (!selectedAsset && assets.length > 0) {
			setSelectedAsset(assets[0]);
		}
	}, [selectedAsset, setSelectedAsset, assets]);
	
	if (!selectedAsset) return null;
	
	return (
		<SelectField
			id="selectedAsset"
			options={assets.map((asset) => ({ label: asset, value: asset }))}
			name="selectedAsset"
			defaultValue={selectedAsset}
			label="Selected Asset"
			onChange={(e) => setSelectedAsset(e.target.value as string)}
		/>
	);
};

const Trading = ({
	assets,
	brokerOrders,
	assetResolution,
	setAssetResolution,
	isLoading,
	backtest,
	orderAnnotations,
}: Props): ReactNode => {
	const [selectedAsset, setSelectedAsset] = useState<string | null>(null);
	const [currentTradingTab, setCurrentTradingTab] = useState(TABLE);
	
	const mints = getUnixTimestamp(backtest?.config.interval.start ?? "");
	const maxts = getUnixTimestamp(backtest?.config.interval.end ?? "");
	
	// Create a fetcher for the ChartContainer
	const [fetch] =	useLazyGetCandleDataForAssetQuery();
	const fetchAssetData: Fetcher = async(mints: number, maxts: number): Promise<FetchedData> => {
		return await fetch({
			symbol: selectedAsset ?? "",
			resolution: assetResolution,
			tsFrom: mints,
			tsTo: maxts,
		}).unwrap();
	};
	
	return (
		<Grid container item direction="column">
			<Grid item>
				<Typography variant="h2">Trading</Typography>
			</Grid>
			<Grid item>
				<TabButtons
					currentTab={currentTradingTab}
					handleTabChange={(newTab) => setCurrentTradingTab(newTab)}
					tabs={[
						{
							label: TABLE,
							icon: ListAltIcon,
						},
						{
							label: CHARTS,
							icon: TimelineIcon,
						},
					]}
				/>
			</Grid>
			<Grid container item direction="column" spacing={1}>
				<Grid item>
					<SelectedAssetField
						assets={assets}
						selectedAsset={selectedAsset}
						setSelectedAsset={setSelectedAsset}
					/>
				</Grid>
				<GridContainer>
					{currentTradingTab === CHARTS && brokerOrders && selectedAsset && (
						<ChartContainer
							mints={mints}
							maxts={maxts}
							aggLevel={assetResolution as AggLevelType}
							fetcher={fetchAssetData}
							liveEvents={orderAnnotations}
						/>
					)}
					{currentTradingTab === TABLE && brokerOrders && (
						<BrokerOrderTable
							events={brokerOrders.orders}
							isLoading={isLoading}
						/>
					)}
				</GridContainer>
			</Grid>
		</Grid>
	);
};

export default Trading;
