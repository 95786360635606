import { Text } from "@react-three/drei";
import {
	CHART_OBJECT_ANNOTATION_DEFAULTS,
	CHART_OBJECT_DEFAULTS,
} from "consts";
import { calcX, calcY } from "../helpers";
import type { ReactNode } from "react";
import type { ChartAnnotationObject, ScaledObject } from "types";

interface AnnotationProps
	extends Pick<ChartAnnotationObject, "rotation" | "style" | "font" | "fontSize">, ScaledObject {
	annotations: ChartAnnotationObject[],
}

const defaultProps = {
	font: CHART_OBJECT_ANNOTATION_DEFAULTS.font,
	fontSize: CHART_OBJECT_ANNOTATION_DEFAULTS.fontSize,
	scale: CHART_OBJECT_DEFAULTS.scale,
	rotation: CHART_OBJECT_DEFAULTS.rotation,
	style: CHART_OBJECT_ANNOTATION_DEFAULTS.style,
};

const SCALE_Y_ADJUST = 500.0;

const Annotations = ({
	annotations,
	rotation,
	style,
	font,
	fontSize,
	scaleFactor,
}: AnnotationProps): ReactNode => {
	return (
		<>
			{annotations.map((a) => (
				<Text
					key={a.objId}
					scale={[
						scaleFactor.scaleY / SCALE_Y_ADJUST,
						scaleFactor.scaleY / SCALE_Y_ADJUST,
						0,
					]}
					position={[
						calcX(a.position.x, scaleFactor),
						calcY(a.position.y, scaleFactor),
						a.position.z || 0,  // default param is 0
					]}
					rotation={[
						a?.rotation?.x ?? rotation.x,
						a?.rotation?.y ?? rotation.y,
						a?.rotation?.z ?? rotation.z,
					]}
					color={a.style.color || style.color}
					font={a.style.font || font}
					fontSize={a.style.fontSize || fontSize}
					fillOpacity={a.style.fillOpacity || style.fillOpacity}
					outlineColor={a.style.outlineColor || style.outlineColor}
					outlineWidth={a.style.outlineWidth || style.outlineWidth}
				>
					{a.text}
				</Text>
			))}
		</>
	);
};

Annotations.defaultProps = defaultProps;

export default Annotations;
